
export var mixinFras = { 
    
  methods: {

    // recargo lineas de facturas
    async recargar() {      
      //this.schema.ctrls.yy.value= new Date().getFullYear().toString();
      this.obtener_records();      
    },


    // obtengo registros y los ordeno
    async obtener_records() {
      await this.get_records('ini');
      this.ordenar_records();
    },


    // ordenación registros 
    ordenar_records() {    
      // id=> 0:Nº Factura, 1:Importe, 2:Fecha Ascendente, 3:Fecha Descendente
      var id= this.schema.ctrls.orden.value;
      var records = JSON.parse(JSON.stringify(this.$store.state[this.stName].records));

      this.$store.commit(this.stName + '/data2State', { prop:'records', value:records.sort((a, b) => {
        if (Number(id) === 3) {
          if (b[this.itemsOrden[id].c] < a[this.itemsOrden[id].c]) return -1;

        } else {
          if (a[this.itemsOrden[id].c] < b[this.itemsOrden[id].c]) return -1;
        }

        return 0;
        })
      });
    },


    // muestro factura
    ver_Fra(evt) {
      this.ver_fichero(evt.row.id, evt.row.Ftp, 1);
    },


    // muestro documento suplido
    ver_Doc(evt) {
      this.ver_fichero(evt.row.id, evt.row.Ftp, 2);
    },


    // llamada API para obtener el fichero (fra/doc) asociado al id recibido
    async ver_fichero(id, Ftp, tp) {
      var args = { tipo: 'fnc', accion: 'facturas', fn_args: { accion: "fichero", id: id, Ftp: Ftp, tp: tp }};
        console.log('args: ', args);
      // llamada a la API          
      var apiResult = await this.$store.dispatch("ajaxRequest", { args: args });
      console.log('apiResult: ', apiResult);
      if (apiResult.sql.error) {
        this.$root.$alert.open(apiResult.sql.msg, 'error');
        return;
      }

      // abro documento
      window.open(this.$store.state.G.url_tmp + apiResult.r[0], "_blank");    
    },


    // filtro botonera del Grid según el valor del campo Ftp
    // 1 - Facturas Exp (Btn Factura y Documento)
    // 2 - Suplidos (Btn Documento)
    // 3 - Liquidaciones APDS (Btn Factura)
    // 4 - Gastos (Btn Factura)
    get_btnSitem(row)
    {
      let bt= JSON.parse(JSON.stringify(this.Entorno.btra));

      bt.row.extra[0].show= row.Ftp!= "2"? 1:0;
      bt.row.extra[1].show= row.doc=="1"? 1:0;
      bt.row.extra[2].show= Object.keys(this.accionRow).length? 1:0;
      return bt;
    },


    // filtro el menú de opciones según permisos y según el campo 'Ftp'
    get_itemsMenu(item) {               
      return this.itemsMenu.filter(elem => {
        // compruebo permisos. Si no lo tiene definido, muestro el btn
        if (this.schema.btra.extra[elem.accion] && Number(this.schema.btra.extra[elem.accion])== 0) return;
        
        // compruebo campo Ftp
        if ((elem.id== "0" || elem.id=="1") && Number(item.Ftp)< 3) return elem;          
        if (elem.id== "2" && item.Ftp== "3") return elem;
        if (elem.id== "3" && item.Ftp== "4") return elem;
      });       
    },


    // abro componente dinámicamente según el item pulsado en el menú de opciones
    open_menuComp(menu, row) {       
      this.accionRow= { id: row.exp_id };
      this.disparo= !this.disparo;
      this.componente_dinamico= menu.comp;
      this.modal= true;
    },

  }
};
