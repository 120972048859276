<!-- Listado de Facturas -->

<template>
  <div class="common_Fras">
     <v-dialog v-model="dialog" content-class="modal">
      <template v-slot:activator="{on}">

        <!-- Botón para abrir Finder Documentos -->
        <v-btn
          v-on='on'
          v-bind="$cfg.btra_cfg.standard"
          v-on:click="recargar"
          v-show="show=='1'"
          title="Facturas">
            <v-icon dark>{{ "mdi-receipt" }}</v-icon>
        </v-btn>
      </template>


      <div class="centrado" v-if="schema">
        <v-sheet :elevation="4">

          <!-- Cabecera -->
          <base_Header
            :Entorno="Entorno.header"
            @onEvent="dialog=false">
          </base_Header>

          <!-- Filtro de Busqueda -->
          <div class="contenedor" :style="Entorno.filtro.style" :class="Entorno.filtro.class">
            <div style="display:flex; align-items:center">
              <div class="white" style="display:flex">
                <v-select
                  v-bind="$select"
                  v-model="schema.ctrls.orden.value"
                  :label="schema.ctrls.orden.label"
                  :items="itemsOrden"
                  item-value="d"
                  item-text="n"
                  @change="ordenar_records">
                </v-select>

                <v-select
                  v-bind="$select"
                  v-model="schema.ctrls.yy.value"
                  :label="schema.ctrls.yy.label"
                  :items="$store.state[stName].recordsDetalle[1]"
                  item-value="d"
                  item-text="n"
                  @change="obtener_records">
                </v-select>
              </div>
            </div>
          </div>

          <!-- Contenido -->
          <div class="conflex">
            <div class="columna" style="width:890px">

              <!-- Grid -->
              <base_Fgrid
                :padre="stName"
                :Entorno="Entorno"
                @onEvent="event_Grid">

                <!-- Slot campos del grid -->
                <!-- <template v-slot:fields_grid="{ Sitem, Sheader }">
                  {{ get_field(Sitem, Sheader) }} -->
                  <!-- </div> -->
                  <!-- <div v-if="Sheader.value=='tipo' && Sitem.name" class="light-blue--text text--darken-3"> {{ Sitem.name }}</div>
                </template> -->

                <!-- Slot campo acciones del Grid -->
                <template v-slot:acciones="{ Sitem }">
                  <div style="display:flex">

                    <!-- Menú opciones -->
                    <!-- <v-menu down offset-y>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          icon
                          color='red'
                          v-on='on'
                          v-bind="$cfg.btn.busca">
                            <v-icon right dark>{{ "mdi-dots-horizontal" }}</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item
                          v-for="(item, index) in get_itemsMenu(Sitem)"
                          :key="index"
                          @click="open_menuComp(item, Sitem)">
                            <v-list-item-title>{{ item.label }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu> -->

                    <!-- Botonera según permisos -->
                    <btracombi
                      :btra_permisos="schema.btra"
                      :btra_particular="get_btnSitem(Sitem)"
                      btra_modulo="mrow"
                      @onEvent="event_btra_Row({ accion:$event.accion, row:Sitem })">
                    </btracombi>
                  </div>
                </template>
              </base_Fgrid>
            </div>
          </div>

          <!-- Componente cargado desde el botón de opciones de cada fila -->
          <v-dialog
            v-model="modal"
            content-class="modal"
            persistent>
              <div class="centrado">
                <component
                  :is="componente_dinamico"
                  :padre="stName"
                  :accion="accion"
                  :accionRow="accionRow"
                  :disparo="disparo"
                  @onEvent="event_Mto">
                </component>
              </div>
          </v-dialog>

          <component
            syncUpdate
            :is="componenteDinamico"
            :storeRaiz="stName"
            :masterStore="stName"
            :filtro="filtroAux"
            tipoFiltro="exp"
            @onEvent="$event.accion==6 || $event.accion=='salir'? componenteDinamico=null : ''">
          </component>
        </v-sheet>
      </div>
     </v-dialog>
  </div>
</template>



<script>

  import { mixinFinder } from "@/mixins/mixinFinder.js";
  import { mixinFras } from "@/mixins/mixinFras.js";
  import plugs from "@/common/general_plugs";
  import frasExpF from "@/components/frasExpF";
  const base_Header = () => plugs.groute("base_Header.vue", "base");
  const base_Fgrid = () => plugs.groute("base_Fgrid_maub.vue", "base");
  const btracombi = () => plugs.groute("btracombi.vue", "comp");
  const exp_M = () => plugs.groute("exp_M.vue", "comp");

  export default {
    mixins: [mixinFinder, mixinFras],
    components: { base_Header, base_Fgrid, btracombi, exp_M, frasExpF },

    props: {
      padre: { type:String, default: ''},
      id: { type: [String, Number], default: "" },
      show: { type: [String, Boolean], default: "" },
      tip: { type: [String, Number], default: "0" },
      /* modulo: { type: String, default: "" }, */
      componenteTipo: { type:String, default:'FM' },
      accionRow: { type:[Object, Array], default: ()=> []},
      Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.F)); }}
    },

    data() {
      return {
        schema: null,
        api:'common_Fras_F',
        stName:'stFcommon_Fras',

        headers:{},
        dialog:false,
        itemsOrden: [],
        itemsMenu: [],
        componente_dinamico:null,
        modal:false,
        accion:"0",
        //accionRow:{},
        disparo:false,
        filtroAux:'',
        componenteDinamico:null,
        storeName:''
      };
    },


    methods: {

      ini_data(){
        console.log("DEV " + this.api + " ********************** (ini_data)");

        // guardo Schema del store en variable local
        this.schema = this.$store.state[this.stName].schema;

        // configuro Entorno
        this.Entorno.header.titulo = "Listado Facturas";
        this.Entorno.grid.autoload= false;
        this.Entorno.grid.bind['items-per-page']= -1;
        this.Entorno.btra= JSON.parse(JSON.stringify(this.$cfg.btra_tipo["F"]));
        this.Entorno.btra.row.extra= [
          { accion: "ver_Fra", btn: "btn_ver_fra", show: 1 },
          { accion: "ver_Doc", btn: "btn_ver_doc", show: 1 },
          { accion: "email", btn: "btn_sendEmail", show: 0 },
          { accion:'facturacion', btn: "btn_facturacion", show: 1 },
        ];

        // cabecera
        this.headers = {
            header: [
              { text: "T", value: "T" },
              { text: "Exped./Ref.", value: "expediente" },
              //{ text: "F", value: "F" },
              { text: "Factura", value: "factura" },
              { text: "Fecha", value: "fecha", filtro:'fecha'  },
              { text: "Importe", value: "importe", filtro:'num'  },
              { text: "Asiento", value: "asiento" },
              { text: "Acciones", value: "acciones", sortable: false, width: "auto", slot:true}
            ]
        };

        // establezco cabecera
        this.Entorno.grid.headers = this.set_headers();

        // items adicionales
        // ordenación filas Grid
        this.itemsOrden= [
          { d: "0", n: "Nº Factura", c: "factura" },
          { d: "1", n: "Importe", c: "importe" },
          { d: "2", n: "Fecha Ascendente", c: "fecha" },
          { d: "3", n: "Fecha Descendente", c: "fecha" }
        ];

        // menu opciones
        this.itemsMenu= [
          { id: "0", label: "Expedientes", accion:'ver_Exp', comp:'exp_M' },
          /* { id: "1", label: "Facturación", accion:'ver_Fact', comp:'' },
          { id: "2", label: "Liquidación", accion:'ver_Liquid', comp:'' },
          { id: "3", label: "Gastos", accion:'ver_Gastos', comp: '' }, */
        ]

      },


      //
      ini_schemaCtrls() {
        this.schema.ctrls.tip.value= this.tip;
      },


      //
      get_records_fin() {
        if (!this.$store.state[this.stName].recordsDetalle[0].length) return;
        this.schema.ctrls.yy.value= this.$store.state[this.stName].recordsDetalle[0][0].yy;
      },


      // envio de email a la cuenta seleccionada
      email(evt) {
        if (!evt.row || !Object.keys(evt.row).length) return;

        this.$root.$alert.open('Enviamos eMail Liquidacion ?<br>APD: <b>'
                               + this.accionRow.name + '</b><br>Factura: <b>' + evt.row.factura
                               + '</b><br>Expedientes: <b>' + evt.row.exped
                               + '</b><br>Importe: <b>' + evt.row.importe + '</b><br><br>', 'confirm')
        .then(r=> { if (r) this.envio_email(evt.row.id); });
      },

      async envio_email(id) {
        //igual que pdf_factura
        let args = { tipo: 'fnc', accion: 'apds', fn_args: { accion: "factura", id, ema: '*', opc: 1 }};
        let apiResult = await this.$store.dispatch("ajaxRequest", { args: args });
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }
        this.$root.$alert.open('Email enviado correctamente', 'success');
      },


      // muestro ventana facturación
      async facturacion(evt) {
        console.log('EVT: ', evt);
          this.filtroAux= evt.row.expediente;
          this.componenteDinamico= "frasExpF";
        },

    },

  };
</script>
